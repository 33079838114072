<div id="modal2" class="modal bottom-sheet">
  <div class="modal-content">
    <h4>How many colums?</h4>
    <div class="center-align">
      <img class="responsive-img column-selector" (click)="addColumn(1)" src="assets/images/one-column.png" />
      <img class="responsive-img column-selector" (click)="addColumn(2)" src="assets/images/two-columns.png" />
    </div>
  </div>
  <div class="modal-footer">
    <!-- <a href="#!" class="modal-action modal-close waves-effect waves-green btn-flat">Agree</a> -->
  </div>
</div>